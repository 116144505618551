<template>
  <div>
    <PostFormComp pageTitle="Service Feature" hideDetail="1" thumenailDescription="แนะนำให้ใช้ไฟล์ *.svg ขนาดรูป 400x400 pixel" />
  </div>
</template>

<script>
import PostFormComp from '@/components/content/post/PostFormComp.vue';

export default {
  name: 'PostFormHomeGuru',
  components: {
    PostFormComp
  },
  created() {
    this.$store.commit('setPageTitle', 'Service Feature');
    this.$store.commit('setHeadTitle', 'Service Feature');
  },
}
</script>